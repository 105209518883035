// Install the latest version of starknet with npm install starknet@next and import starknet
import * as starknet from 'starknet';

// Generate public and private key pair.

//const keyPair = starknet.ec.genKeyPair();
//const starkKeyPub = starknet.ec.getStarkKey(keyPair);
var starkKeyPub = //'0x06f58de936b4dc5f964b143af9981a234264b234851099bfb3fd01000d5f8908';
                    '0x1986b427385df75f4bbf9d687fc197d43688dbb813e9cc6e9553089060f7c08';
const provider = new starknet.Provider();

async function getstark() {


  let data = await fetch("./contracts/OZAccount.json");
  let COMPILED_WALLET_CONTRACT_JSON = await data.json();

  const account = await provider.deployContract({
    contract: COMPILED_WALLET_CONTRACT_JSON,
    constructorCallData: [starkKeyPub],
    addressSalt: starkKeyPub,
  });

  // You can also check this address on https://goerli.voyager.online/
  console.log("Account address          ", account.contract_address);
  console.log("Account transaction hash ", account.transaction_hash);

  const keyPair = starknet.ec.genKeyPair();
  const starkKeyPub2 = starknet.ec.getStarkKey(keyPair);

  const d2 = await fetch("./contracts/PhYMoney.json");
  const compiledErc20 = await d2.json();

  const erc20AddressLocal = await provider.deployContract({
    contract: compiledErc20,
    constructorCallData: [starkKeyPub2],
    addressSalt: starkKeyPub2,
  });

  const erc20Address = erc20AddressLocal.contract_address;
  const erc20 = new starknet.Contract(compiledErc20.abi, erc20Address);

  const cars = new Array("100", "100");
  const { code: codeErc20Mint, transaction_hash: txErc20Mint } = await erc20.invoke('mint',
    {
      to: erc20Address, 
      amount: "100",
    }
  );


}

getstark();